var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Create a note for: "+_vm._s(_vm.customer.name +' '+ _vm.customer.lastName))]),_c('v-textarea',{staticClass:"my-3",attrs:{"placeholder":"Write a note","disabled":_vm.loading,"outlined":"","hide-details":""},model:{value:(_vm.details),callback:function ($$v) {_vm.details=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"details"}}),_c('v-btn',{attrs:{"color":"primary","width":"140","disabled":_vm.details == '',"loading":_vm.loading,"depressed":"","rounded":""},on:{"click":_vm.createNote}},[_vm._v(" Create ")]),(_vm.notesS.length != 0)?[_c('div',{staticClass:"text-h6 d-flex mt-4"},[_vm._v(" System Notes "),_c('v-spacer'),_c('div',{staticClass:"mt-2"},[(_vm.typeS == 'asc')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.typeS = 'desc'}}},on),[_c('v-icon',[_vm._v("mdi-sort-ascending")])],1)]}}],null,false,2982797924)},[_c('span',[_vm._v("Sort Asc")])])]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.typeS = 'asc'}}},on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}],null,false,662330788)},[_c('span',[_vm._v("Sort Desc")])])]],2),_c('div',{staticClass:"mt-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":_vm.getNotes}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1390288525)},[_c('span',[_vm._v("Refresh")])])],1)],1)]:_vm._e(),_c('v-layout',{staticClass:"fill-height mt-4",attrs:{"column":""}},[(_vm.notesS.length != 0)?[_c('v-slide-y-transition',{attrs:{"group":""}},[_vm._v(" "+_vm._s(/* TODO: Separar esto en un componente */)+" "),_vm._l((_vm.notess),function(note,i){return _c('v-alert',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,staticClass:"mb-2 cursor-pointer",attrs:{"color":"accent","text":"","dark":"","dense":""},on:{"click":function($event){return _vm.showDetailsS(i)}}},[_c('v-layout',{staticClass:"mb-2",attrs:{"justify-space-between":""}},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_c('v-badge',{attrs:{"color":"error","content":note.comments.length,"value":note.comments.length > 0,"offset-x":"18","offset-y":"10"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"accent"}},[_vm._v(" mdi-comment-text-multiple ")])],1),_vm._v(" "+_vm._s(note.createdBy ? note.createdBy.fullname : _vm.$t("System"))+" ")],1),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateTime(note.createAt))+" ")])]),_c('v-layout',{staticClass:"mb-2",attrs:{"justify-space-between":""}},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(note.details)+" ")]),_c('div',{staticClass:"text-caption text-capitalize"},[_vm._v(" "+_vm._s(note.type && note.type == "ANY" ? "Note" : note.type)+" ")])])],1)})],2)]:_vm._e()],2)],2),_vm._v(" "+_vm._s(/* Notes list */)+" "),_c('v-col',[(_vm.notesP.length != 0)?[_c('div',{staticClass:"text-h6 d-flex"},[_vm._v(" Notes "),_c('v-spacer'),_c('div',{staticClass:"mt-2"},[(_vm.type == 'asc')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.type = 'desc'}}},on),[_c('v-icon',[_vm._v("mdi-sort-ascending")])],1)]}}],null,false,1045202935)},[_c('span',[_vm._v("Sort Asc")])])]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.type = 'asc'}}},on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}],null,false,1955309495)},[_c('span',[_vm._v("Sort Desc")])])]],2),_c('div',{staticClass:"mt-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":_vm.getNotes}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1390288525)},[_c('span',[_vm._v("Refresh")])])],1)],1)]:_vm._e(),_c('v-layout',{staticClass:"fill-height",attrs:{"column":""}},[(_vm.notesP.length != 0)?[_c('v-slide-y-transition',{attrs:{"group":""}},[_vm._v(" "+_vm._s(/* TODO: Separar esto en un componente */)+" "),_vm._l((_vm.notes),function(note,i){return _c('v-alert',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,staticClass:"mb-2 cursor-pointer",attrs:{"color":"blue","text":"","dark":"","dense":""},on:{"click":function($event){return _vm.showDetails(i)}}},[_c('v-layout',{staticClass:"mb-2",attrs:{"justify-space-between":""}},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(note.createdBy ? note.createdBy.fullname : _vm.$t("System"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateTime(note.createAt))+" ")])]),_c('v-layout',{staticClass:"mb-2",attrs:{"justify-space-between":""}},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(note.details)+" ")]),_c('div',{staticClass:"text-caption text-capitalize"},[_vm._v(" "+_vm._s(note.type && note.type == "ANY" ? "Note" : note.type)+" ")])])],1)})],2)]:_c('div',{staticClass:"grey--text lighten-1 font-weight-light text-h5 ma-auto"},[_vm._v(" This customer don't have notes ")])],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }